import { Button, Col, message, Row, Select } from "antd";
import moment from "moment";
import { Fragment, useEffect, useState } from "react";
import { getDistrictApi } from "../api/api.getDistrict";
import { getSearchByStateApi } from "../api/api.getSearchByState";
import { getStatesApi } from "../api/api.getStates";
import { DATE_FORMAT, AGE_LIST } from "../constants";
import TabCalendarView from "../TabCalendar";
const { Option } = Select;
const SearchByState = (props) => {
  const [states, setStates] = useState([]);
  const [isStateLoading, setIsStateLoading] = useState(false);
  const [selectedStateId, setSelectedStateId] = useState(null);
  const [isDistrictLoading, setIsDistrictLoading] = useState(false);
  const [districts, setDistricts] = useState([]);
  const [selectedDistrictId, setSelectedDistrictId] = useState(null);
  const [selectedDate, setSelectedDate] = useState(moment());
  const [centers, setCenters] = useState([]);
  const [isCentersLoading, setIsCentersLoading] = useState(false);
  const [isAutoRefreshLoading, setIsAutoRefreshLoading] = useState(false);
  const [filterAge, setFilterAge] = useState('18 - 44');
  useEffect(() => {
    getStatesApi(setStates, setIsStateLoading);
  }, []);
  const handleStateChange = (stateId) => {
    setSelectedStateId(stateId);
    setDistricts([]);
    setCenters([]);
    setSelectedDistrictId(null);
    getDistrictApi(stateId, setDistricts, setIsDistrictLoading);
  };
  const handleDistrictChange = (districtId) => {
    setSelectedDistrictId(districtId);
  };

 const handleFilterAge = age => {
  setFilterAge(age);
   };
  const handleSearch = () => {
    if (selectedDistrictId && selectedDate)
      getSearchByStateApi(
        selectedDistrictId,
        selectedDate.format(DATE_FORMAT),
        setCenters,
        isAutoRefreshLoading ? setIsAutoRefreshLoading : setIsCentersLoading,
        filterAge
      );
  };
  useEffect(() => {
    if (selectedDate && selectedDistrictId) {
      setIsCentersLoading(true);
      setCenters([]);
      handleSearch();
    }
  }, [selectedDate]);

  
  return (
    <Fragment>
      <Row gutter={[20, 10]}>
        <Col>
          <span className="search-lbl">State:</span>
          <Select
            style={{ width: 150 }}
            loading={isStateLoading}
            onChange={handleStateChange}
            value={selectedStateId}
            placeholder="Select state"
            disabled={isAutoRefreshLoading}
            size="small"
          >
            {states.map(({ state_id, state_name }) => (
              <Option key={state_id} value={state_id}>
                {state_name}
              </Option>
            ))}
          </Select>
        </Col>
        <Col>
          <span className="search-lbl">District:</span>
          <Select
            placeholder="Select district"
            style={{ width: 150 }}
            loading={isDistrictLoading}
            onChange={handleDistrictChange}
            value={selectedDistrictId}
            disabled={isAutoRefreshLoading}
            size="small"
          >
            {districts.map(({ district_id, district_name }) => (
              <Option key={district_id} value={district_id}>
                {district_name}
              </Option>
            ))}
          </Select>
        </Col>
        <Col>
        <span className="search-lbl">Age:</span>
        <Select
            style={{ width: 150 }}
            onChange={handleFilterAge}
            value={filterAge}
            size="small"
            disabled={isAutoRefreshLoading}
          >
            {AGE_LIST.map(({ key, label }) => (
              <Option key={key} value={label}>
                {label}
              </Option>
            ))}
          </Select>
        </Col>
                
        <Col>
          <Button
            type="primary"
            size="small"
            onClick={() => {
              if(selectedStateId === null) {
                message.warning('Please select state');
              } else if(selectedDistrictId === null) {
                message.warning('Please select district');
              }else {
                setSelectedDate(moment());
              }
              
            }}
            loading={isCentersLoading}
          >
            Search
          </Button>
        </Col>
      </Row>

      <TabCalendarView
        selectedDate={selectedDate}
        setSelectedDate={setSelectedDate}
        dataSource={centers}
        isCentersLoading={isCentersLoading}
        isAutoRefreshLoading={isAutoRefreshLoading}
        handleSearch={handleSearch}
      />
    </Fragment>
  );
};
export default SearchByState;
