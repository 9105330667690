import { Button, Tabs, Tooltip } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { StickyContainer, Sticky } from "react-sticky";
import { DATE_FORMAT } from "../constants";
import TabCenterItem from "../TabCenterItem";
const { TabPane } = Tabs;

const TabCalendarView = ({ selectedDate, setSelectedDate, dataSource,isCentersLoading, isAutoRefreshLoading, handleSearch }) => {
  const [activeTabKey, setActiveTabKey] = useState('0');
  const dayMoveButtons = {
    left: selectedDate > moment() && (
      <Tooltip placement="topLeft" title="Go back">
        <Button
          type="primary"
          size="small"
          style={{ marginRight: 5 }}
          onClick={() => handleDateBack()}
        >
          {"<"}
        </Button>
      </Tooltip>
    ),
    right: (
      <Tooltip placement="topLeft" title="Go forward">
        <Button type="primary" size="small" onClick={() => handleDateForward()}>
          {">"}
        </Button>
      </Tooltip>
    ),
  };
  const renderTabBar = (props, DefaultTabBar) => (
    <Sticky bottomOffset={80}>
      {({ style }) => (
        <DefaultTabBar
          {...props}
          className="site-custom-tab-bar"
          style={{ ...style }}
        />
      )}
    </Sticky>
  );
  const handleDateBack = () => {
    setSelectedDate(moment(selectedDate).subtract(7, "days"));
    setActiveTabKey('0');
  };
  const handleDateForward = () => {
    setSelectedDate(moment(selectedDate).add(7, "days"));
    setActiveTabKey('0');
  };
  const getFormattedDate = (days,format) => {
    let title = null;
    switch (days) {
      case 0:
        title = moment(selectedDate, DATE_FORMAT).format(format);
        break;
      case 1:
        title = moment(selectedDate, DATE_FORMAT)
          .add(1, "day")
          .format(format);
        break;
      case 2:
        title = moment(selectedDate, DATE_FORMAT)
          .add(2, "days")
          .format(format);
        break;
      case 3:
        title = moment(selectedDate, DATE_FORMAT)
          .add(3, "days")
          .format(format);
        break;
      case 4:
        title = moment(selectedDate, DATE_FORMAT)
          .add(4, "days")
          .format(format);
        break;
      case 5:
        title = moment(selectedDate, DATE_FORMAT)
          .add(5, "days")
          .format(format);
        break;
      case 6:
        title = moment(selectedDate, DATE_FORMAT)
          .add(6, "days")
          .format(format);
        break;
      default:
        break;
    }
    return title;
  };
  const TabTitle = ({ days, format }) => {
    return getFormattedDate(days, format);    
  };
  
  const handleTabClick = key => {
    setActiveTabKey(key);
    setTimeout(handleSearch,600);
  };
  return (
    <div style={{ paddingTop: 8 }}>
      <StickyContainer>
        <Tabs
          tabBarExtraContent={dayMoveButtons}
          renderTabBar={renderTabBar}
          size="small"
          onChange={handleTabClick}
          activeKey={activeTabKey}
        >
          <TabPane tab={<TabTitle days={0} format="DD MMM YY" />} key="0">
            <TabCenterItem
              dataSource={dataSource}
              sourceDate={moment(selectedDate).format(DATE_FORMAT)}
              isCentersLoading={isCentersLoading}
              isAutoRefreshLoading={isAutoRefreshLoading}
            />
          </TabPane>
          <TabPane tab={<TabTitle days={1} format="DD MMM YY" />} key="1">
            <TabCenterItem
              dataSource={dataSource}
              sourceDate={moment(selectedDate)
                .add(1, "day")
                .format(DATE_FORMAT)}
              isCentersLoading={isCentersLoading}
              isAutoRefreshLoading={isAutoRefreshLoading}
            />
          </TabPane>
          <TabPane tab={<TabTitle days={2} format="DD MMM YY" />} key="2">
            <TabCenterItem
              dataSource={dataSource}
              sourceDate={moment(selectedDate)
                .add(2, "days")
                .format(DATE_FORMAT)}
              isAutoRefreshLoading={isAutoRefreshLoading}
              isCentersLoading={isCentersLoading}
            />
          </TabPane>
          <TabPane tab={<TabTitle days={3} format="DD MMM YY" />} key="3">
            <TabCenterItem
              dataSource={dataSource}
              sourceDate={moment(selectedDate)
                .add(3, "days")
                .format(DATE_FORMAT)}
              isAutoRefreshLoading={isAutoRefreshLoading}
              isCentersLoading={isCentersLoading}
            />
          </TabPane>
          <TabPane tab={<TabTitle days={4} format="DD MMM YY" />} key="4">
            <TabCenterItem
              dataSource={dataSource}
              sourceDate={moment(selectedDate)
                .add(4, "days")
                .format(DATE_FORMAT)}
              isAutoRefreshLoading={isAutoRefreshLoading}
              isCentersLoading={isCentersLoading}
            />
          </TabPane>
          <TabPane tab={<TabTitle days={5} format="DD MMM YY" />} key="5">
            <TabCenterItem
              dataSource={dataSource}
              sourceDate={moment(selectedDate)
                .add(5, "days")
                .format(DATE_FORMAT)}
              isAutoRefreshLoading={isAutoRefreshLoading}
              isCentersLoading={isCentersLoading}
            />
          </TabPane>
          <TabPane tab={<TabTitle days={6} format="DD MMM YY" />} key="6">
            <TabCenterItem
              dataSource={dataSource}
              sourceDate={moment(selectedDate)
                .add(6, "days")
                .format(DATE_FORMAT)}
              isAutoRefreshLoading={isAutoRefreshLoading}
              isCentersLoading={isCentersLoading}
            />
          </TabPane>
        </Tabs>
      </StickyContainer>
    </div>
  );
};
export default TabCalendarView;
