import axios from "axios";
import { BASE_LOCATION_API_URL } from "../constants";

export const getDistrictApi = async (stateId,setState,setLoading) => {
    const url = `${BASE_LOCATION_API_URL}/districts/`+stateId;
    setLoading(true);
    await axios.get(url).then(response => {
        const { data } = response;
        const { districts } = data;
        setState(districts);
        setLoading(false);
    }).catch(() => {
        setState([]);
        setLoading(false);
    });
  }