import { LoadingOutlined } from "@ant-design/icons";
import { Col, List, Row, Tag, Typography } from "antd";
import { Fragment } from "react";

const { Title, Text } = Typography;
const TabCenterItem = ({ dataSource, sourceDate, isAutoRefreshLoading, isCentersLoading }) => {
  const getSlotTagColor = (availableCapacity) => {
    if (availableCapacity === 0) return "red-inverse";
    if (availableCapacity > 10) return "green-inverse";
    else return "orange-inverse";
  };
  const ListItem = ({ item }) => {
    const { name, sessions, address,fee_type, vaccine_fees = [] } = item;
    const sessionData = sessions.find(({ date }) => date === sourceDate);
    const { available_capacity, vaccine = "", min_age_limit, slots } = sessionData || {};
    const vaccinePriceData = vaccine_fees.find(item => item.vaccine === vaccine);
    
    return (
      <List.Item>
        <div style={{ width: "100%" }}>
          <Row gutter={[0, 10]}>
            <Col lg={7} xs={24}>
              <Title level={5} style={{ fontWeight: 150, marginBottom: -4 }}>
                {name.toUpperCase()}
              </Title>
              <Text type="secondary" style={{ fontSize: 12 }}>
                {address}
              </Text>
            </Col>
            {sessionData !== null && sessionData !== undefined ? (
              <Fragment>
                <Col lg={3} xs={24}>
                  <span className="session-desc-lbl">Availability:</span>
                  {
                    <Tag color={getSlotTagColor(available_capacity)}>
                      {available_capacity === 0 ? (
                        "Booked"
                      ) : isAutoRefreshLoading ? (
                        <LoadingOutlined />
                      ) : (
                        available_capacity
                      )}
                    </Tag>
                  }
                  <div style={{ paddingTop: 5 }}>
                    <span className="session-desc-lbl">Age limit:</span>
                    <Tag>{min_age_limit}+</Tag>
                  </div>
                </Col>
                <Col lg={4} xs={24}>
                  <span className="session-desc-lbl">Vaccine:</span>
                  {vaccine !== "" ? (
                    <Tag>{vaccine}</Tag>
                  ) : (
                    <Tag>
                      {isAutoRefreshLoading ? <LoadingOutlined /> : "NA"}
                    </Tag>
                  )}
                  <div style={{ paddingTop: 5 }}>
                    <span className="session-desc-lbl">Price:</span>
                    {vaccinePriceData !== null && vaccinePriceData !== undefined ? (
                      <Tag color="blue">{vaccinePriceData.fee}</Tag>
                    ):(fee_type === 'Free' ? <Tag >Free</Tag> : <Tag color="blue">Paid</Tag>)}
                  </div>
                </Col>
                <Col>
                {
                  slots.map(item => (<Tag >{item}</Tag>))
                }
                </Col>
              </Fragment>
            ) : (
              <Col>
                <Tag>{isAutoRefreshLoading ? <LoadingOutlined /> : "NA"}</Tag>
              </Col>
            )}
          </Row>
        </div>
      </List.Item>
    );
  };
  return (
    <List
      size="small"
      bordered
      dataSource={dataSource}
      renderItem={(item) => <ListItem item={item} />}
      loading={isCentersLoading}
      pagination
    />
  );
};
export default TabCenterItem;
