import axios from "axios";
import { BASE_APPT_API_URL } from "../constants";

export const getSearchByPincodeApi = async (
  pincode,
  date,
  setState,
  setLoading,
  filterAge
) => {
  const url = `${BASE_APPT_API_URL}/calendarByPin?pincode=${pincode}&date=${date}`;
  setLoading(true);
  await axios
    .get(url)
    .then((response) => {
      const { data } = response;
      const { centers } = data;
      const filteredCenters = centers.filter(item => {
        return item.sessions.filter(({min_age_limit}) => {
          return filterAge === '45+' ? min_age_limit === 45 : min_age_limit === 18;
        }).length > 0;
      }).map(item => {
        return{
          ...item,
          sessions: item.sessions.filter(({min_age_limit}) => {
            return filterAge === '45+' ? min_age_limit === 45 : min_age_limit === 18;
          })
        };
      });
      setState(filteredCenters.sort((a,b) => a.name.localeCompare(b.name)));
      setLoading(false);
    })
    .catch(() => {
      setState([]);
      setLoading(false);
    });
};
