import { Tabs } from "antd";
import "antd/dist/antd.css";
import Title from "antd/lib/typography/Title";
import { useState } from "react";
import "./App.css";
import SearchByPincode from "./SearchByPincode";
import SearchByState from "./SearchByState";

const { TabPane } = Tabs;
const App = () => {
 const [searchTabKey, setSearchTabKey] = useState('1');
  return (
    <div className="app-body">
     <Title level={3} style={{fontWeight:200, marginBottom:0, marginTop:0}}>Covid Vaccine Locator [in]</Title>
      <Tabs defaultActiveKey="1" activeKey={searchTabKey} onChange={key => setSearchTabKey(key)}>          
          <TabPane tab="Pincode" key="1">
            <SearchByPincode setSearchTabKey={setSearchTabKey}/>
          </TabPane>
          <TabPane tab="State/District" key="2">
            <SearchByState />
          </TabPane>
        </Tabs>
    </div>
  );
};

export default App;
