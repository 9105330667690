import { InfoCircleOutlined } from "@ant-design/icons";
import { Alert, Button, Col, Input, message, Result, Row, Select, Spin } from "antd";
import moment from "moment";
import { Fragment, useEffect, useState } from "react";
import addNotification from "react-push-notification";
import { getSearchByPincodeApi } from "../api/api.getSearchByPinCode";
import { getSearchByPincodeNotifyApi } from "../api/api.getSearchByPinCodeNotify";
import { AGE_LIST, DATE_FORMAT } from "../constants";
import TabCalendarView from "../TabCalendar";
import { useInterval } from "../useInterval";

const { Option } = Select;
const SearchByPincode = (props) => {
  const {setSearchTabKey} = props;
  const [pincode, setPinCode] = useState("");
  const [selectedDate, setSelectedDate] = useState(moment());
  const [centers, setCenters] = useState([]);
  const [isCentersLoading, setIsCentersLoading] = useState(false);
  const [isAutoRefreshLoading, setIsAutoRefreshLoading] = useState(false);
  const [filterAge, setFilterAge] = useState("18 - 44");
  const [notificationData, setNotificationData] = useState(null);
  const [hasNotificationPermission, setHasNotificationPermission] = useState(false);
  const {Notification = null} = window;
  const numberOnlyRegex = /^[0-9\b]+$/;
  const handlePinCodeChange = (evt) => {
    const { target } = evt;
    const { value } = target;
    if(numberOnlyRegex.test(value))
      setPinCode(value);
  };
  const handleSearch = () => {
    getSearchByPincodeApi(
      pincode,
      selectedDate.format(DATE_FORMAT),
      setCenters,
      isAutoRefreshLoading ? setIsAutoRefreshLoading : setIsCentersLoading,
      filterAge
    );
  };
  useEffect(() => {
    if (selectedDate && pincode !== null && pincode.length > 0) {
      setIsCentersLoading(true);
      setCenters([]);
      handleSearch();
    }
  }, [selectedDate]);

  const handleFilterAge = (age) => {
    setFilterAge(age);
  };

  useInterval(() => {
    if(hasNotificationPermission && notificationData !== null) {
      handlSearchNotification();
    }
  }, hasNotificationPermission && notificationData !== null ? 300000 :null);

  const handlSearchNotification = () => {
    if(notificationData !== null) {
      const { notifyPinCode,notifySelectedDate,notifyFilterAge } = notificationData;
      getSearchByPincodeNotifyApi(
        notifyPinCode,
        notifySelectedDate.format(DATE_FORMAT),
        setCenters,
        gotSlotAvilNotification,
        notifyFilterAge
      );
    }
  };
  const gotSlotAvilNotification = () => {
   const {notifyPinCode,notifyFilterAge} = notificationData;
    notifyUser('Vaccine available in your pincode ('+notifyPinCode+') for age '+notifyFilterAge);
    setNotificationData(null);
    setSearchTabKey('1');
  };
  const handleNotifyMe = () => {
    if(pincode.length === 6) {
      setNotificationData({
        notifyPinCode: pincode,
        notifySelectedDate: selectedDate,
        notifyFilterAge: filterAge,
      });
      if(Notification) {
        Notification.requestPermission().then(function (permission) {
          setHasNotificationPermission(permission === 'granted');
        });
        notifyUser('Notification subscribed successfully for pincode -'+pincode);
      }
    } else {
      message.warning('Please enter/correct pincode');
    }    
    
  };
  const handleNotifyRemove = () => {
    setNotificationData(null);
  };
  const notifyUser = (message) => {
    addNotification({
      title:'Covid Vaccine Tracker',
      message,
      theme: 'red',
      duration: 8000,
      vibrate: 10,
      native: true // when using native, your OS will handle theming.
  });
  };
  const ReturnResultTitle = () => {
    if(Notification === null) return 'Browser does not support notification';
    return 'Successfully subscribed for notification';

  };
  const SubTitleResult = () => {
    if(Notification == null)
     return '';
     return (<div>
       <div>
       {'Pincode: '+notificationData.notifyPinCode+', Age: '+notificationData.notifyFilterAge+', Date: '+moment(notificationData.notifySelectedDate).format("DD")+' to '+moment(notificationData.notifySelectedDate, DATE_FORMAT).add(6, "days").format("DD MMM YY")}
       </div>
       <div style={{color:'red'}}>
       <InfoCircleOutlined /> Please keep your browser running
       </div>
     </div>);
  }
  return notificationData !== null && hasNotificationPermission ? (
    <Result
      status={Notification !== null ? "success" : "error"}
      title={<ReturnResultTitle />}
      subTitle={<SubTitleResult />}
      extra={[
        <Button type="primary" key="console" onClick={handleNotifyRemove}>
          {Notification !== null ? 'Delete notify me' : 'Back'}
        </Button>
      ]}
    />
  ) : (
    <Fragment>
      <Row gutter={[10, 10]}>
        <Col>
          <span className="search-lbl">Pincode:</span>
          <Input
            placeholder="Enter pincode"
            size="small"
            style={{ width: 150 }}
            maxLength={6}
            value={pincode}
            onChange={handlePinCodeChange}
          />
        </Col>
        <Col>
          <span className="search-lbl">Age:</span>
          <Select
            style={{ width: 150 }}
            onChange={handleFilterAge}
            value={filterAge}
            size="small"
            disabled={isAutoRefreshLoading}
          >
            {AGE_LIST.map(({ key, label }) => (
              <Option key={key} value={label}>
                {label}
              </Option>
            ))}
          </Select>
        </Col>
        <Col>
          <Button
            size="small"
            type="primary"
            onClick={() => {
              if(pincode.length === 6) {
                setSelectedDate(moment());                
              }
              else {
                message.warning('Please enter/correct pincode');
              }
              
            }}
            loading={isCentersLoading}
          >
            Search
          </Button>
        </Col>
        <Col lg={0} xs={4}></Col>
        <Col>
          <Button type="primary" size="small" onClick={handleNotifyMe} danger>
            Notify me
          </Button>
        </Col>
      </Row>
      <TabCalendarView
        selectedDate={selectedDate}
        setSelectedDate={setSelectedDate}
        dataSource={centers}
        isCentersLoading={isCentersLoading}
        isAutoRefreshLoading={isAutoRefreshLoading}
        handleSearch={handleSearch}
      />
    </Fragment>
  );
};
export default SearchByPincode;
