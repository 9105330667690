import axios from "axios";
import { BASE_LOCATION_API_URL } from "../constants";

export const getStatesApi = async (setStates,setIsStateLoading) => {
    const url = `${BASE_LOCATION_API_URL}/states`;
    setIsStateLoading(true);
     const states = await axios.get(url).then(response => {
        const { data } = response;
        const { states } = data;
        setStates(states);
        setIsStateLoading(false);
    }).catch(() => {
        setStates([]);
        setIsStateLoading(false);
    });
  }